const config = {
  apiKey: "AIzaSyDLq_cvDu3t8a9jlVw18TERO7z-ys3ce-A",
  authDomain: "veloxforce.firebaseapp.com",
  projectId: "veloxforce",
  storageBucket: "veloxforce.appspot.com",
  messagingSenderId: "789957707202",
  appId: "1:789957707202:web:455d9c92876fe19b1f825b",

  partnermerchant: "EMSW7B6L82PPN",
  paypalbncode: "360payment_PSP_360payment",
  paypalsecret: "EDi9p2vzKjDHChkL7rrEcZoy2my9yTqjDeo6vLhm8qFoalb2-_ch8Lu9Oiah4byatK-3SwuGv6u1Ovi8",
  paypalclientid: "AcV-njm_09klSbExl7-vcupKfxOpEApdqJL5OUpu8Szyx7S86yqaeN2l9Mk3die7YQYNGFgJtTvNN9KF",
  paypalauthurl: "https://api.paypal.com",
  paypalurl: "https://api-m.paypal.com",
  isprod: "true",
}

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error('No Firebase configuration object provided.' + '\n' +
    'Add your web app\'s configuration object to firebase-config.js')
  } else {
    return config
  }
}
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirebaseConfig } from '../lib'

const app = initializeApp(getFirebaseConfig())
const functions = getFunctions(app)
const buildPayment = httpsCallable(functions, 'onCall-buildPayment')

function Terminal() {
  let { id } = useParams()
  const [message, setMessage] = useState('Loading ...')
  const [paymentBuilt, setPaymentBuilt] = useState(false)
  let navigate = useNavigate()

  useEffect(() => {
    if (!paymentBuilt) {
      getPaymentId()
    }
  })
 
  const getPaymentId = async () => {
    try {
      const result = await buildPayment({ id })
      const {
        payId,
      } = result.data
      console.log('payId: ', payId)

      setTimeout(() => {
        navigate(`/pay?id=${payId}`)
      }, 500)
    } catch (err) {
      console.log('error: ', err.message)
      setMessage('No active transaction')
    } finally {
      setPaymentBuilt(true)
    }
  }

  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row justify-content-center m-4">
        <div className="card">
          <div className="card-body">
          <h1>{message}</h1>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terminal
import React from "react"

function PayPalReceipt(props) {
  const {
    accountName,
    accountPhone,
    accountEmail,
    accountStreet,
    accountCity,
    accountState,
    accountZip,
    accountImage,
    address,
    city,
    state,
    zip,
    refId,
    amount,
    authCode,
    invoiceUrl,
    termsAndConditions,
    capture_signature,
    paymentSource,
    payer_email
  } = props
  console.log('props: ' + JSON.stringify(props))
  console.log('paymentSource: ' + JSON.stringify(paymentSource))
  
  return (
    <div>
      {accountImage && (
        <img src={accountImage} alt="image" className="img-fluid rounded mt-1"/>
      )}
      <h3>{accountName}</h3>
      <h5>{accountStreet}</h5>
      <h5>{accountCity}, {accountState} {accountZip}</h5>
      <h5>{accountPhone}</h5>
      <h5>{accountEmail}</h5>
      <div className="col d-flex justify-content-center">
        <div className="card bg-light mt-2" style={{ width: "35rem" }}>
          <div className="card-body">
          { (paymentSource=='paypal' || paymentSource=='paylater') ? (<table border="0" cellpadding="10" cellspacing="0" align="center"><tr><td align="center"></td></tr><tr><td align="center"><a href="https://www.paypal.com/uk/webapps/mpp/paypal-popup" title="How PayPal Works" onclick="javascript:window.open('https://www.paypal.com/uk/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-150px.png" border="0" alt="PayPal Logo"/></a></td></tr></table>) 
          : '' }
          { paymentSource=='venmo'? (<img src='https://images.ctfassets.net/gkyt4bl1j2fs/273APNSE63eFeQGVaNokaJ/da5948b50660aa0a99b02ea2e6990e2f/Blue.png?w=424&h=84&q=50&fm=webp&bg=transparent' alt="image" />) 
          : '' }

          </div>
          <div className="card-header">
            Receipt of {paymentSource} Payment
            <br/>
            Email {payer_email}
          </div>
          <div className="card-body">
            <div className="container text-start">
              <div className="row">
                
                <div className="col-sm">
                <b>Invoice Id</b> 
                </div>
                <div className="col-sm">
                <b>Payment Method</b> 
                </div>
              </div>
              <div className="row">
                
                <div className="col-sm">
                  {refId}
                </div>
                <div className="col-sm">
                  {paymentSource}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <b>Address</b>
                </div>
                <div className="col-sm">
                  <b>PayPal Order Id</b>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <div className="row">
                    <div className="col-sm">
                    {address}
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-sm">
                      {city} {state}, {zip}
                    </div>
                  </div>
                </div>
                <div className="col-sm">
                  {authCode}
                </div>
              </div>
              <div className="row">
                
                <div className="col-sm">
                  <b>Amount</b>
                </div>
                <div className="col-sm">
                  <b>Status</b>
                </div>
              </div>
              <div className="row">
                
                <div className="col-sm">
                  {new Intl.NumberFormat('en-US',
                        { style: 'currency', currency: 'USD',
                          minimumFractionDigits: 2 }).format(amount)}
                </div>
                <div className="col-sm">
                  PROCESSED
                </div>
              </div>
              
              <div className="row">
                <div className="col-sm">
                  {invoiceUrl ? (<b>Invoice</b>) : ''}
                </div>
              
              </div>

              <div className="row">
                <div className="col-sm">
                  {invoiceUrl ? (<a href={invoiceUrl}>Link</a>) : ''}
                </div>
                
              </div>
              
              <div className="row">
                <div className="col-sm">
                  {termsAndConditions ? (<p >{termsAndConditions}</p>) : ''}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  {capture_signature ? ( <img src={capture_signature} alt="image" className="img-fluid m-1"/>) : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PayPalReceipt
